var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upper-marketing d-md-none text-center p-3 mb-3" },
    [
      _vm.wavppCampaign
        ? _c("p", { staticClass: "exclusive mb-0" }, [
            _vm._v("\n        Exclusive for Walmart Associates\n    ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.wavppCampaign
        ? _c("img", {
            staticClass: "w-100",
            attrs: { src: require("./images/Walmart_logo.png"), alt: "Walmart" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h4", [_vm._v("Please sign-in or create your profile to continue")]),
      _vm._v(" "),
      _c("b-link", { attrs: { href: "#benefits", "smooth-scrolling": "" } }, [
        _vm._v("\n        See benefits\n    ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }