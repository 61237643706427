<template>
    <wavpp-value-prop v-cloak v-if="wavppCampaign" />
    <i-heart-value-prop v-cloak v-else-if="iheartCampaign" />
    <standard-value-prop v-cloak v-else />
</template>
<script>
import { get } from "vuex-pathify";
import _ from "lodash";
import StandardValueProp from "./components/StandardValueProp";
import WavppValueProp from "./components/WavppValueProp";
import IHeartValueProp from "./components/IHeartValueProp/IHeartValueProp";

export default {
    name: "ValuePropBlock",
    components: { WavppValueProp, IHeartValueProp, StandardValueProp },
    computed: {
        campaignType: get("campaign/type"),
        wavppCampaign() {
            return (
                !_.isNil(this.campaignType) &&
                _.toLower(this.campaignType) === "wavpp"
            );
        },
        iheartCampaign() {
            return (
                !_.isNil(this.campaignType) &&
                _.toLower(this.campaignType) === "iheart"
            );
        }
    }
};
</script>
