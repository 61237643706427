<template>
    <div id="valueProp" class="d-flex flex-column">
        <div class="mb-5 d-flex">
            <div class="icon-container">
                <img
                    src="/assets/images/car_icon.jpg"
                    alt="Find your dream car"
                />
            </div>
            <div>
                <p>{{ $t("component.valuePropBlock.standard.propOne") }}</p>
            </div>
        </div>
        <div class="mb-5 d-flex">
            <div class="icon-container pt-0">
                <img
                    src="/assets/images/savings_icon.jpg"
                    alt="Everyday low prices"
                />
            </div>
            <div>
                <p>{{ $t("component.valuePropBlock.standard.propTwo") }}</p>
            </div>
        </div>
        <div class="mb-5 d-flex">
            <div class="icon-container p-1">
                <img
                    src="/assets/images/complete_icon.jpg"
                    alt="Worry-free driving"
                />
            </div>
            <div>
                <p>{{ $t("component.valuePropBlock.standard.propThree") }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "StandardValueProp"
};
</script>
<style lang="scss">
#valueProp {
    .icon-container {
        min-width: 60px;
        margin-right: 30px;
        padding-top: 5px;
    }
    img {
        width: 100%;
    }
}
</style>
