<template>
    <div v-cloak class="upper-marketing d-md-none text-center p-3 mb-3">
        <p v-if="wavppCampaign" class="exclusive mb-0">
            Exclusive for Walmart Associates
        </p>
        <img
            v-if="wavppCampaign"
            class="w-100"
            src="./images/Walmart_logo.png"
            alt="Walmart"
        />
        <h4>Please sign-in or create your profile to continue</h4>
        <b-link href="#benefits" smooth-scrolling>
            See benefits
        </b-link>
    </div>
</template>

<style lang="scss" scoped>
.upper-marketing {
    background-color: $very-light-gray;
    border-radius: 5px;

    h4 {
        font-size: 22px;
        font-family: $helvetica-65-medium;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }

    .exclusive {
        font-size: 14px;
        font-family: $helvetica-55-Roman;
    }
}
</style>

<script>
import { get } from "vuex-pathify";
import _ from "lodash";

export default {
    computed: {
        campaignType: get("campaign/type"),
        wavppCampaign() {
            return (
                !_.isNil(this.campaignType) &&
                _.toLower(this.campaignType) === "wavpp"
            );
        }
    }
};
</script>
