var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.wavppCampaign
    ? _c("wavpp-value-prop", {})
    : _vm.iheartCampaign
    ? _c("i-heart-value-prop", {})
    : _c("standard-value-prop", {})
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }