var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column", attrs: { id: "valueProp" } },
    [
      _c("div", { staticClass: "mb-5 d-flex" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("component.valuePropBlock.standard.propOne")))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-5 d-flex" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", [
          _c("p", [
            _vm._v(_vm._s(_vm.$t("component.valuePropBlock.standard.propTwo")))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-5 d-flex" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", [
          _c("p", [
            _vm._v(
              _vm._s(_vm.$t("component.valuePropBlock.standard.propThree"))
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-container" }, [
      _c("img", {
        attrs: {
          src: "/assets/images/car_icon.jpg",
          alt: "Find your dream car"
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-container pt-0" }, [
      _c("img", {
        attrs: {
          src: "/assets/images/savings_icon.jpg",
          alt: "Everyday low prices"
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-container p-1" }, [
      _c("img", {
        attrs: {
          src: "/assets/images/complete_icon.jpg",
          alt: "Worry-free driving"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }