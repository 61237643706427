<template>
    <div
        v-if="!isKiosk"
        v-cloak
        class="social-sign-up d-flex flex-column align-items-center mb-4"
    >
        <div class="or-separator d-flex justify-content-between mb-3">
            <div class="horizontal-line">
                <hr />
            </div>
            or
            <div class="horizontal-line">
                <hr />
            </div>
        </div>

        <div id="google-login-button" class="mb-2" />

        <div
            class="fb-login-button"
            data-size="medium"
            data-button-type="continue_with"
            data-auto-logout-link="false"
            data-use-continue-as="true"
            data-onlogin="onFacebookLogin"
            data-scope="email"
        />
    </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
    computed: {
        campaignType: get("campaign/type"),
        isKiosk() {
            return this.campaignType === "kiosk";
        }
    },
    created() {
        const el = document.getElementById("auth2_script_id");
        if (!el) {
            const gplatformScript = document.createElement("script");
            gplatformScript.setAttribute(
                "src",
                "https://apis.google.com/js/platform.js?onload=onGapiLoad"
            );
            gplatformScript.setAttribute("async", true);
            gplatformScript.setAttribute("defer", "defer");
            gplatformScript.setAttribute("id", "auth2_script_id");
            document.head.appendChild(gplatformScript);
        }

        window.onGapiLoad = () => {
            window.gapi.signin2.render("google-login-button", {
                scope: "profile email",
                longtitle: true,
                theme: "dark",
                onsuccess: googleUser => {
                    const idToken = googleUser.getAuthResponse().id_token;

                    window.submitFORM("/sign-up/sso", {
                        provider: "google",
                        idToken
                    });
                },
                onfailure: () => {
                    console.log("Error");
                }
            });
        };
    }
};
</script>
