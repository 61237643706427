<template>
    <div id="iheart-value-prop" class="d-flex flex-column">
        <div class="list-block p-4 mb-4 d-none d-md-block">
            <div class="title text-center mb-1">
                {{ $t("component.valuePropBlock.iheart.title") }}
            </div>
            <img
                class="w-100 py-3"
                src="./images/iheart.png"
                alt="iHeartMedia"
            />
            <p class="please-sign-in text-center">
                Please sign in or create your profile to continue.
            </p>
        </div>
        <p>
            <strong>Exclusive Benefits</strong>
        </p>
        <div class="list-block p-4">
            <ul class="mb-0">
                <li>{{ $t("component.valuePropBlock.iheart.listItemOne") }}</li>
                <li>{{ $t("component.valuePropBlock.iheart.listItemTwo") }}</li>
                <li>
                    {{ $t("component.valuePropBlock.iheart.listItemThree") }}
                </li>
                <li>
                    {{ $t("component.valuePropBlock.iheart.listItemFour") }}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: "IHeartValueProp"
};
</script>
<style lang="scss">
#iheart-value-prop {
    .list-block {
        background: $very-light-gray;
        width: 100%;

        .please-sign-in {
            font-size: 22px;
            font-family: $helvetica-65-medium;

            @include media-breakpoint-down(sm) {
                font-size: 14px;
                font-style: italic;
            }
        }

        .title {
            font-family: $helvetica-55-Roman;
        }

        ul {
            line-height: 25px;
            padding-left: 35px;
        }

        .supplier-brands {
            padding-left: 45px;
            max-width: 300px;
            font-family: $helvetica-75-bold;

            .comma-list {
                display: inline;
                list-style: none;
                padding: 0;

                li {
                    display: inline;
                }
            }
        }
    }
}
</style>
