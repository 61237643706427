var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column", attrs: { id: "wavpp-value-prop" } },
    [
      _c("div", { staticClass: "list-block p-4 mb-4 d-none d-md-block" }, [
        _c("div", { staticClass: "title text-center mb-1" }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.$t("component.valuePropBlock.wavpp.title")) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "w-100",
          attrs: {
            src: require("../../images/Walmart_logo.png"),
            alt: "Walmart"
          }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "please-sign-in text-center" }, [
          _vm._v(
            "\n            Please sign in or create your profile to continue.\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "list-block p-4" }, [
        _c("ul", { staticClass: "mb-0" }, [
          _c("li", [
            _vm._v(_vm._s(_vm.$t("component.valuePropBlock.wavpp.listItemOne")))
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(_vm._s(_vm.$t("component.valuePropBlock.wavpp.listItemTwo")))
          ]),
          _vm._v(" "),
          _c("li", [
            _vm._v(
              _vm._s(_vm.$t("component.valuePropBlock.wavpp.listItemFour"))
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "supplier-brands" }, [
          _c(
            "ul",
            { staticClass: "comma-list" },
            _vm._l(_vm.supplierBrands, function(brand, key) {
              return _c("li", { key: key }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(brand) +
                    "\n                "
                )
              ])
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("strong", [_vm._v("Exclusive Benefits")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }